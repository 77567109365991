import PropTypes from "prop-types";
import { useState } from "react";

// material-ui
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// assets
import { Card, Edit2, Logout, Profile, Profile2User } from "iconsax-react";

const ProfileTab = ({ handleLogout }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <List
      component="nav"
      sx={{ p: 1, "& .MuiListItemIcon-root": { minWidth: 32 } }}
    >
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event) => handleListItemClick(event, 1)}
      >
        <ListItemIcon>
          <Profile variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 2}
        onClick={handleLogout}
        sx={{
          "&:hover": {
            backgroundColor: "#f591915c",
          },
        }}
      >
        <ListItemIcon>
          <Logout color="red" variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
};

export default ProfileTab;
